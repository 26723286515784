import './App.css';
import icon from './assets/img/icon.png' 
import logo from './assets/img/logo.svg' 
import banner from './assets/img/banner.png' 
import telegramIco from './assets/img/telegramIco.svg' 
import twitterIco from './assets/img/twitterIco.svg' 
import social3 from './assets/img/social3.svg' 
import social4 from './assets/img/social4.svg' 
import sec2Img from './assets/img/sec2Img.png' 
// import sec3Img from './assets/img/sec3Img.png' 
import sec3Img from './assets/img/sec3Img.gif' 
import aboutIco from './assets/img/aboutIco.png' 
import sec4Img from './assets/img/sec4Img.png' 
import sec5Img1 from './assets/img/sec5Img1.png' 
import sec5Img2 from './assets/img/sec5Img2.png' 
import sec5Img3 from './assets/img/sec5Img3.png' 
import sec5Img4 from './assets/img/sec5Img4.png' 
import sec6Img from './assets/img/sec6Img.gif' 
import sec7Img from './assets/img/sec7Img.png' 
import sec7Img2 from './assets/img/sec7Img2.gif' 
import footerICo from './assets/img/footerICo.png' 
import bannerEffect1 from './assets/img/bannerEffect1.png' 
import bannerEffect2 from './assets/img/bannerEffect2.png' 
import sec2Ball from './assets/img/sec2Ball.JPG'; 
import argentinaLogo from './assets/img/argentinaLogo.png'; 
import brazilLogo from './assets/img/brazilLogo.png'; 
import cornerIco from './assets/img/cornerIco.svg'; 
import arrowDown from './assets/img/arrowDown.svg'; 


function App() {
  return (
    <div className="App">
      <header>
        <div className="container">
          <nav className='navbar'>
            <div className="icon"><img src={icon} alt="" /></div>
            <div className="logo"><img src={logo} alt="" /></div>
            <a href="https://dapp.capitan-copa.io/"><div className="themeBtn">CAPITAN DAPP</div></a>
          </nav>
        </div>
        <div className="bannerMain">
          <img src={banner} alt="" />
        </div>
        <img src={bannerEffect1} alt="" className="bannerEffect1" />
        <img src={bannerEffect2} alt="" className="bannerEffect2" />
      </header>

      <section className='sec6'>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="content">
                <div className="cardRow">
                  <BettCard1 />
                  <BettCard />
                </div>
                <div className="head">bet on copa 2024</div>
                <div className="desc">Our multi-user betting dApp lets you bet on your favorite teams. The journey begins with the Copa America and extends to other leagues like never before, allowing you to support your top team all the way.</div>
                <a href="https://dapp.capitan-copa.io/"><button className="themeBtn">CAPITAN DAPP</button></a>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="imgDiv">
                <img src={sec6Img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <div className="marqSec">
        <marquee behavior="" direction="left"> 
          <div className="content">
            <div className="text">The Capitan's journey will begin with the Copa America 2024 - $CCA</div>
            <div className="text">The Capitan's journey will begin with the Copa America 2024 - $CCA</div>
            <div className="text">The Capitan's journey will begin with the Copa America 2024 - $CCA</div>
          </div>
        </marquee>
      </div>
      <div className="sec3Main">
      <section className='sec2'>
        {/* <img src={sec2Ball} alt="" className="effect1" /> */}
        <div className="container">
          <div className="row ai-center">
            <div className="col-md-7">
              <div className="content">
                <h2>Join the Capitan on his journey of football predictions.</h2>
                <a href='https://app.uniswap.org/swap?outputCurrency=0x69695b9484ded06433f4eb8081ffbcb9ff349d47&use=V2&chain=base' target='_blank' className="themeBtn">buy $CCA</a>
                <div className="code">CA: 0x69695b9484Ded06433f4Eb8081FFbCB9Ff349d47</div>
                {/* <h3>Join us on socials!</h3> */}
                <div className="socialMain">
                  <a href="https://t.me/capitancopa" target="_blank" rel="noopener noreferrer">
                    <img src={telegramIco} alt="" className="icon" />
                  </a>
                  <a href="https://x.com/CaptainCopaBase" target="_blank" rel="noopener noreferrer">
                    <img src={twitterIco} alt="" className="icon" />
                  </a>
                  <a href="https://www.dextools.io/app/en/base/pair-explorer/0xdfbbdc548DcF7603B5c672B90fbc77D4f88caeB7?t=1718913341087" target="_blank" rel="noopener noreferrer">
                    <img src={social3} alt="" className="icon" />
                  </a>
                  <a href="https://dexscreener.com/base/0x69695b9484ded06433f4eb8081ffbcb9ff349d47" target="_blank" rel="noopener noreferrer">
                    <img src={social4} alt="" className="icon" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="imgDiv">
                <img src={sec2Img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
        <section className='sec3'>
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="content">
                  <div className="head">About <img src={aboutIco} alt="" /></div>
                  <div className="desc">Love football? Obsessed with crypto?</div>
                  <div className="desc">Then you'll love Capitan Football, the first platform for head-to-head and multi-user bets. Starting with the Copa America, one of the world's biggest football events, Capitan is set to take over.</div>
                  <div className="desc mb-5">Join us as Capitan unites crypto enthusiasts and football fans worldwide.</div>
                  <button className="themeBtn">buy $CCA</button>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="imgDiv">
                  <img src={sec3Img} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='sec4'>
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-5">
                <div className="content">
                  <div className="head">Tokenomics</div>
                  <div className="desc">Supply: 1,000,000,000</div>
                  <div className="desc">Tax: 5/5</div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="imgDiv">
                  <img src={sec4Img} alt="" />
                </div>
              </div>
            </div>
            <div className="r2">
              <div className="item2"><span></span>Liquidity</div>
              <div className="item1"><span></span>Rewards</div>
              <div className="item3 m-0"><span></span>Partnership</div>
            </div>
          </div>
        </section>

        {/* <section className='sec5'>
          <div className="container">
            <div className="rowMain">
                <div className="depositCard">
                  <div className="r1">
                    <div className="icon"><img src={sec5Img1} alt="" /></div>
                    <span>vs</span>
                    <div className="icon"><img src={sec5Img2} alt="" /></div>
                  </div>
                  <div className="content">
                    <div className="text1">will Jamaica win or lose</div>
                    <div className="badge">Win 3.5X</div>
                    <div className="badge">Lose 3.5X</div>
                    <div className="time"><span>Bets close in:</span> 1 hr 23s</div>
                  </div>
                </div>
                <div className="depositCard2">
                  <div className="r1">
                    <div className="icon"><img src={sec5Img1} alt="" /></div>
                    <span>vs</span>
                    <div className="icon"><img src={sec5Img2} alt="" /></div>
                  </div>
                  <div className="content">
                    <div className="r2">
                      <div className="text1">Place your bet amount in $</div>
                      <div className="badge">$128</div>
                    </div>
                    <div className="r2">
                      <div className="text1">Lose 3.5X</div>
                      <div className="val">53,034,000 <span> $CCA</span></div>
                    </div>
                    <div className="text-center mt-4">
                      <div className="themeBtn">Deposit now</div>
                    </div>
                  </div>
                </div>
                <div className="depositCard">
                  <div className="r1">
                    <div className="icon"><img src={sec5Img1} alt="" /></div>
                    <span>vs</span>
                    <div className="icon"><img src={sec5Img2} alt="" /></div>
                  </div>
                  <div className="content">
                    <div className="text1">will Jamaica win or lose</div>
                    <div className="badge">Win 3.5X</div>
                    <div className="badge">Lose 3.5X</div>
                    <div className="time"><span>Bets close in:</span> 1 hr 23s</div>
                  </div>
                </div>
            </div>
          </div>
        </section> */}

      </div>
        <div className="chooseMain">
            <section className='sec7'>
              <div className="container">
                <div className="head">Choose</div>
                <div className="imgDiv">
                  <img src={sec7Img} alt="" />
                  <img className='img2' src={sec7Img2} alt="" />

                </div>
              </div>
            </section>
        </div>

      <footer>
        <div className="container">
          <div className="footerInner">
            <div className="icon">
              <img src={footerICo} alt="" />
            </div>
            <div className="content">
              <p>Capitan has no affiliation with any football player or club nor indorsed by copa 2024</p>
              <p>Capitan is a meme coin with no intrensic value or expectation of financial return, the token is useless and for entertainment purpose only.</p>
              <p className='mb-0'>Capitan is not an investment or financial product and published content should not taken as financial advice.</p>
            </div>
            <a href="mailto:info@capitan-copa.io" className='themeBtn'>SEND EMAIL</a>
          </div>   
        </div>
      </footer>

    </div>
  );
}

const BettCard = () => {
  return (
    <div className="betCardMain">
        <div className="betCard">
            <div className="team">
                <div className="imgDiv">
                    <img src={argentinaLogo} alt="" />
                </div>
                <div className="title">Argentina</div>
            </div>
            <div className="score">
                <div className="count">2-3</div>
                <div className="time">23’</div>
            </div>
            <div className="team">
                <div className="imgDiv">
                    <img src={brazilLogo} alt="" />
                </div>
                <div className="title">Brazil</div>
            </div>
        </div>
        <div className="btnMain">
            <button className="themeBtn1">BET NOW</button>
        </div>
    </div>
  )
}
const BettCard1 = () => {
  return (
    <div className="betCardMain1">
        <div className="betCard">
            <div className="team">
                <div className="imgDiv">
                    <img src={argentinaLogo} alt="" />
                </div>
                <div className="title">Argentina</div>
            </div>
            <div className="score">
                <div className="count">vs</div>
            </div>
            <div className="team">
                <div className="imgDiv">
                    <img src={brazilLogo} alt="" />
                </div>
                <div className="title">Brazil</div>
            </div>
        </div>
        <div className="btnMain">
            <div className="corner">Corners <span>(<img src={cornerIco} alt="" /> 6/32)</span></div>
            <div className="r1">
              <div className="text">BET</div>
              <div className="number">
                <img src={arrowDown} alt="" />
                6.5
              </div>
            </div>
            <div className="r1 mb-3">
              <div className="text">AMOUNT</div>
              <div className="TEXT">$100</div>
            </div>
            <div className="btnRow">
              <button className="themeBtn1 me-2">EDIT</button>
              <button className="themeBtn1">+ ADD MORE</button>
            </div>
        </div>
    </div>
  )
}

export default App;
